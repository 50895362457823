export default {
  namespaced: true,
  state: () => ({
    meta_data: {
      title: "registro de datos",
    },
    title: "Solicitud",
    general_info: "Información general",
    aditional_info: "Datos adicionales",
    // familiar_info: "Información del familiar",
    text_one:
      "Ingresa los datos para comenzar el proceso de cobro del PTU para Ex-Asociados Bosch",
    text_two:
      "Ingresa los datos y documentación requerida del Ex-asociado Bosch.",
    text_three:
      "Ingresa los datos y documentación requerida del Ex-asociado Bosch.",
    // text_four: "Ingresa los datos y documentación requerida del familiar que hace la solicitud a nombre del difunto.",
    show_form: false,
    form_sended: false,
    beneficiaries: [],
    success_form_sended: 200,
    form: {
      is_ex_associate: [
        {
          label: "¿Realizas la solicitud a nombre del difunto?",
          name: "is_ex_associate",
          type: "radio",
          value: 0,
          mutation: "IsExAssociate",
          options: [
            { label: "Si", value: 1 },
            { label: "No", value: 0 },
          ],
        },
      ],
      // 'familiar_data': [{
      //         'label': 'Nombre(s) y apellido(s)',
      //         'name': 'familiar_first_name',
      //         'type': 'text',
      //         'required': true,
      //         'value': '',
      //         'mutation': "FamiliarFirstName",
      //     },
      //     {
      //         'label': 'Teléfono celular',
      //         'name': 'familiar_lada',
      //         'type': 'options',
      //         'required': true,
      //         'no_spacing': true,
      //         'max_length': "6",
      //         'search': true,
      //         'value': '',
      //         'search_type': 'lada',
      //         'mutation': "FamiliarLada",
      //         'class_styles': 'col-3'
      //     },
      //     {
      //         'label': 'Teléfono celular',
      //         'name': 'familiar_phone',
      //         'type': 'number',
      //         'required': true,
      //         'value': '',
      //         'required_length': "10",
      //         'max_length': "10",
      //         'mutation': "FamiliarPhone",
      //         'class_styles': 'col-8'
      //     },
      //     {
      //         'label': 'Correo electrónico',
      //         'name': 'familiar_email',
      //         'type': 'email',
      //         'required': true,
      //         'value': '',
      //         'mutation': "FamiliarEmail",
      //         'class_styles': 'col-8'
      //     },
      //     {
      //         'label': '',
      //         'name': 'familiar_domain',
      //         'type': 'options',
      //         'no_spacing': true,
      //         'required': true,
      //         'search_type': 'domain',
      //         'search': true,
      //         'value': '',
      //         'mutation': "FamiliarDomain",
      //         'class_styles': 'col-4'
      //     },
      //     {
      //         'label': 'INE del familiar (.pdf max 2mb)',
      //         'name': 'familiar_ine',
      //         'type': 'file',
      //         'required': true,
      //         'value': '',
      //         'mutation': "FamiliarIne",
      //     },

      // ],
      foreign_form: [
        {
          label: "Comprobante de domicilio (.pdf max 2mb)",
          name: "file_aditional_1",
          type: "file",
          required: true,
          value: "",
          mutation: "AddressFile",
        },
      ],
      aditional_data_1: [
        {
          label: "¿Vives en el extranjero?",
          name: "is_foreign",
          type: "radio",
          value: 0,
          mutation: "IsForeign",
          options: [
            { label: "Si", value: 1 },
            { label: "No", value: 0 },
          ],
        },
      ],
      aditional_data_2: [
        {
          label: "¿Cuentas con pensión alimenticia?",
          name: "food_pension",
          type: "radio",
          value: 0,
          mutation: "FoodPension",
          options: [
            { label: "Si", value: 1 },
            { label: "No", value: 0 },
          ],
        },
      ],
      general_content: [
        {
          label: "CURP",
          readonly: true,
          name: "curp",
          type: "text",
          required: true,
          max_length: "18",
          required_length: "18",
          no_spacing: true,
          value: "",
          mutation: "Curp",
        },
        {
          label: "Nombre(s) y apellido(s)",
          readonly: true,
          name: "first_name",
          type: "text",
          required: true,
          value: "",
          mutation: "FirstName",
        },
        // {
        //     'label': 'Teléfono celular',
        //     'name': 'lada',
        //     'type': 'options',
        //     'max_length': "6",
        //     'no_spacing': true,
        //     'required': true,
        //     'search_type': 'lada',
        //     'search': true,
        //     'value': '',
        //     'mutation': "Lada",
        //     'class_styles': 'col-3'
        // },
        {
          label: "Teléfono celular",
          name: "phone",
          no_spacing: true,
          type: "tel",
          required: true,
          value: "",
          required_length: "10",
          max_length: "10",
          mutation: "Phone",
          class_styles: "col-8",
        },
        {
          label: "Correo electrónico",
          name: "email",
          type: "text",
          no_spacing: true,
          required: true,
          value: "",
          mutation: "Email",
          class_styles: "col-8",
        },
        {
          label: "Confirmar correo electrónico",
          name: "validation",
          type: "text",
          no_spacing: true,
          required: true,
          value: "",
          mutation: "Validation",
          class_styles: "col-8",
        },
        // {
        //     'label': '',
        //     'name': 'domain',
        //     'type': 'options',
        //     'no_spacing': true,
        //     'required': true,
        //     'search_type': 'domain',
        //     'search': true,
        //     'value': '',
        //     'mutation': "Domain",
        //     'class_styles': 'col-4'
        // },
        {
          label: "CLABE interbancaria activa",
          name: "clabe",
          type: "tel",
          required: true,
          no_spacing: true,
          required_length: "18",
          max_length: "18",
          value: "",
          mutation: "Clabe",
          class_styles: "col-6",
        },
        {
          label: "Confirmar CLABE",
          name: "clabe_validation",
          type: "tel",
          required: true,
          no_spacing: true,
          required_length: "18",
          max_length: "18",
          value: "",
          mutation: "ClabeValidation",
          class_styles: "col-6",
        },
      ],
      general_content_cfdi: [
        {
          label: "Constancia de situación fiscal (.pdf max 2mb)",
          name: "cfdi",
          type: "file",
          required: true,
          value: "",
          mutation: "CFDI",
        },
      ],
      terms_conditions: [
        {
          label: "Aceptas el ",
          label_link: "Aviso de Privacidad.",
          link_vue: "/privacy",
          type: "checkbox",
          name: "terms_and_conditions",
          value: "",
          mutation: "TermsAndConditions",
          required: true,
        },
      ],
      beneficiaries_content: [],
    },
    beneficiary_structure: [
      {
        label: "Pensionad@",
        name: "beneficiary_fullname",
        readonly: true,
        type: "text",
        value: "",
        mutation: "BeneficiaryFullname",
        class_styles: "col-12",
      },
      // {
      //     'label': 'Teléfono celular',
      //     'name': 'beneficiary_lada',
      //     'type': 'options',
      //     'max_length': "6",
      //     'no_spacing': true,
      //     'required': true,
      //     'search': true,
      //     'search_type': 'lada',
      //     'options': [],
      //     'value': '',
      //     'mutation': "BeneficiaryLada",
      //     'class_styles': 'col-4 nopadding-right'
      // },
      {
        label: "Teléfono celular",
        name: "beneficiary_phone",
        no_spacing: true,
        type: "tel",
        required: true,
        value: "",
        required_length: "10",
        max_length: "10",
        mutation: "BeneficiaryPhone",
        class_styles: "col-6",
      },
      {
        label: "Correo",
        name: "email",
        type: "email",
        no_spacing: true,
        required: true,
        value: "",
        mutation: "BeneficiaryEmail",
        class_styles: "col-6",
      },
      {
        label: "Confirmar correo electrónico",
        name: "validation",
        type: "text",
        no_spacing: true,
        required: true,
        value: "",
        mutation: "BeneficiaryEmailValidation",
        class_styles: "col-6",
      },
      {
        label: "CLABE interbancaria activa",
        name: "clabe",
        type: "tel",
        required: true,
        no_spacing: true,
        required_length: "18",
        max_length: "18",
        value: "",
        mutation: "BeneficiaryClabe",
        class_styles: "col-6 mt-1",
      },
      {
        label: "Confirmación CLABE",
        name: "clabe_validation",
        type: "tel",
        required: true,
        no_spacing: true,
        required_length: "18",
        max_length: "18",
        value: "",
        mutation: "BeneficiaryClabeValidation",
        class_styles: "col-6 mt-1",
      },
      // {
      //     'label': 'Correo electrónico',
      //     'name': 'beneficiary_domain',
      //     'type': 'options',
      //     'no_spacing': true,
      //     'required': true,
      //     'search_type': 'domain',
      //     'search': true,
      //     'value': '',
      //     'mutation': "BeneficiaryDomain",
      //     'class_styles': 'col-4 nopadding-left'
      // },
    ],
    api_data: {
      country_code: {
        data: [],
        endpoint: "catalogs/country_code/",
        data_count: 0,
        mutation: "FormData/mutationAPI_CountryCode",
      },
      email_domain: {
        data: [],
        endpoint: "catalogs/email_domain/",
        data_count: 0,
        mutation: "FormData/mutationAPI_EmailDomain",
      },
      associate: {
        data: [],
        endpoint: "associates/associate/logged/",
        data_count: 0,
        mutation: "FormData/mutationAPI_AssociateLogged",
      },
      handle_request: {
        data: {
          ex_associate: {},
        },
        endpoint: "associates/associate/(id)/",
        mutation: "",
        fetch_response: "FormData/mutationAPI_ResponseHandleForm",
      },
      handle_files: {
        data: {},
        endpoint: "associates/associate/save_documents/",
        mutation: "",
      },
    },
  }),
  mutations: {
    /** API */
    mutationAPI_CountryCode(state, data) {
      state.api_data.country_code.data = data.map((item) => {
        return { name: item.code, value: item.id };
      });
      state.api_data.country_code.data_count = data.length;
    },
    mutationAPI_EmailDomain(state, data) {
      state.api_data.email_domain.data = data.map((item) => {
        return { name: item.name, value: item.id };
      });
      state.api_data.email_domain.data_count = data.length;
    },
    mutationAPI_ResponseHandleForm(state, data) {
      state.success_form_sended = data;
    },
    mutationAPI_AssociateLogged(state, data) {
      state.beneficiaries = data.beneficiaries;
      state.api_data.associate.data = data;
      state.api_data.associate.data_count = data.length;

      var key = "curp";
      state.form.general_content.find(function (element) {
        if (element["name"] == key) {
          element.value = data.ex_associate.curp;
        }
      });

      key = "first_name";
      state.form.general_content.find(function (element) {
        if (element["name"] == key) {
          element.value = data.ex_associate.name;
        }
      });

      if (data.beneficiaries_count > 0) {
        key = "food_pension";
        state.form.aditional_data_2.find(function (element) {
          if (element["name"] == key) {
            element.type = "radio_disabled";
            element.value = 1;
          }
        });
      }
    },
    /** Is ex associate */
    mutation_IsExAssociate(state, newValue) {
      let key = "is_ex_associate";
      state.form.is_ex_associate.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue;
        }
      });
    },

    /** Familiar */
    // mutation_FamiliarFirstName(state, newValue) {
    //     let key = 'familiar_first_name'
    //     state.form.familiar_data.find(function(element) {
    //         if (element['name'] == key) {
    //             element.value = newValue
    //         }
    //     });
    // },
    // mutation_FamiliarLastNameOne(state, newValue) {
    //     let key = 'familiar_last_name_01'
    //     state.form.familiar_data.find(function(element) {
    //         if (element['name'] == key) {
    //             element.value = newValue
    //         }
    //     });
    // },
    // mutation_FamiliarLastNameTwo(state, newValue) {
    //     let key = 'familiar_last_name_02'
    //     state.form.familiar_data.find(function(element) {
    //         if (element['name'] == key) {
    //             element.value = newValue
    //         }
    //     });
    // },
    // mutation_FamiliarLada(state, newValue) {
    //     let key = 'familiar_lada'
    //     state.form.familiar_data.find(function(element) {
    //         if (element['name'] == key) {
    //             element.value = newValue
    //         }
    //     });
    // },
    // mutation_FamiliarLadaOptions(state, newValue) {
    //     let key = 'familiar_lada'
    //     state.form.familiar_data.find(function(element) {
    //         if (element['name'] == key) {
    //             element.options = newValue
    //         }
    //     });
    // },
    // mutation_FamiliarPhone(state, newValue) {
    //     let key = 'familiar_phone'
    //     state.form.familiar_data.find(function(element) {
    //         if (element['name'] == key) {
    //             element.value = newValue
    //         }
    //     });
    // },
    // mutation_FamiliarEmail(state, newValue) {
    //     let key = 'familiar_email'
    //     state.form.familiar_data.find(function(element) {
    //         if (element['name'] == key) {
    //             element.value = newValue
    //         }
    //     });
    // },
    // mutation_FamiliarDomain(state, newValue) {
    //     let key = 'familiar_domain'
    //     state.form.familiar_data.find(function(element) {
    //         if (element['name'] == key) {
    //             element.value = newValue
    //         }
    //     });
    // },
    // mutation_FamiliarDomainOptions(state, newValue) {
    //     let key = 'familiar_domain'
    //     state.form.familiar_data.find(function(element) {
    //         if (element['name'] == key) {
    //             element.options = newValue
    //         }
    //     });
    // },
    // mutation_FamiliarIne(state, newValue) {
    //     let key = 'familiar_ine'
    //     state.form.familiar_data.find(function(element) {
    //         if (element['name'] == key) {
    //             element.value = newValue
    //         }
    //     });
    // },

    /** Foreign */
    mutation_IsForeign(state, newValue) {
      let key = "is_foreign";
      state.form.aditional_data_1.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue;
        }
      });
    },
    mutation_AddressFile(state, newValue) {
      let key = "file_aditional_1";
      state.form.foreign_form.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue;
        }
      });
    },
    mutation_Clabe(state, newValue) {
      let key = "clabe";
      state.form.general_content.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue;
        }
      });
    },

    mutation_ClabeValidation(state, newValue) {
      let key = "clabe_validation";
      state.form.general_content.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue;
        }
      });
    },

    /** General content */
    mutation_Curp(state, newValue) {
      let key = "curp";
      state.form.general_content.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue.toUpperCase();
        }
      });
    },
    mutation_FirstName(state, newValue) {
      let key = "first_name";
      state.form.general_content.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue;
        }
      });
    },
    mutation_Lada(state, newValue) {
      let key = "lada";
      state.form.general_content.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue;
        }
      });
    },
    mutation_LadaOptions(state, newValue) {
      let key = "lada";
      state.form.general_content.find(function (element) {
        if (element["name"] == key) {
          element.options = newValue;
        }
      });
    },
    mutation_Phone(state, newValue) {
      let key = "phone";
      state.form.general_content.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue;
        }
      });
    },
    mutation_Email(state, newValue) {
      let key = "email";
      state.form.general_content.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue;
        }
      });
    },
    mutation_Validation(state, newValue) {
      let key = "validation";
      state.form.general_content.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue;
        }
      });
    },
    mutation_Domain(state, newValue) {
      let key = "domain";
      state.form.general_content.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue;
        }
      });
    },
    mutation_DomainOptions(state, newValue) {
      let key = "domain";
      state.form.general_content.find(function (element) {
        if (element["name"] == key) {
          element.options = newValue;
        }
      });
    },
    mutation_CFDI(state, newValue) {
      let key = "cfdi";
      state.form.general_content_cfdi.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue;
        }
      });
    },
    mutation_TermsAndConditions(state, newValue) {
      let key = "terms_and_conditions";
      state.form.terms_conditions.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue;
        }
      });
    },

    /** Beneficiaries */
    mutation_FoodPension(state, newValue) {
      if (state.form.aditional_data_2[0].value == 0) {
        state.form.beneficiaries_content = [];
      }

      let key = "food_pension";
      state.form.aditional_data_2.find(function (element) {
        if (element["name"] == key) {
          element.value = newValue;
          if (newValue && state.form.beneficiaries_content.length == 0) {
            let clone_beneficiary = state.beneficiary_structure;
            state.beneficiaries.forEach(function (element) {
              let new_record = structuredClone(clone_beneficiary);
              new_record[0].value = element["name"];
              state.form.beneficiaries_content.push(new_record);
            });
          }
        }
      });
      // Get how many associates were at the beginning
      state.form.original_size = state.form.beneficiaries_content.length;
    },
    mutation_BeneficiaryFullname(state, { data, array_key }) {
      let key = "beneficiary_fullname";
      state.form.beneficiaries_content[array_key - 1].find(function (element) {
        if (element["name"] == key) {
          element.value = data;
        }
      });
    },
    // mutation_BeneficiaryLada(state, { data, array_key }) {
    //     let key = 'beneficiary_lada'
    //     state.form.beneficiaries_content[array_key - 1].find(function(element) {
    //         if (element['name'] == key) {
    //             element.value = data
    //         }
    //     });
    // },
    // mutation_BeneficiaryLadaOptions(state, newValue) {
    //     let key = 'beneficiary_lada'
    //     state.form.beneficiaries_content.forEach(function(beneficiary) {
    //         beneficiary.find(function(element) {
    //             if (element['name'] == key) {
    //                 element.options = newValue
    //             }
    //         });
    //     })
    // },
    mutation_BeneficiaryPhone(state, { data, array_key }) {
      let key = "beneficiary_phone";
      state.form.beneficiaries_content[array_key - 1].find(function (element) {
        if (element["name"] == key) {
          element.value = data;
        }
      });
    },
    mutation_BeneficiaryEmail(state, { data, array_key }) {
      let key = "email";
      state.form.beneficiaries_content[array_key - 1].find(function (element) {
        if (element["name"] == key) {
          element.value = data;
        }
      });
    },
    mutation_BeneficiaryEmailValidation(state, { data, array_key }) {
      let key = "validation";
      state.form.beneficiaries_content[array_key - 1].find(function (element) {
        if (element["name"] == key) {
          element.value = data;
        }
      });
    },
    mutation_BeneficiaryDomain(state, { data, array_key }) {
      let key = "beneficiary_domain";
      state.form.beneficiaries_content[array_key - 1].find(function (element) {
        if (element["name"] == key) {
          element.value = data;
        }
      });
    },
    mutation_BeneficiaryDomainOptions(state, newValue) {
      let key = "beneficiary_domain";
      state.form.beneficiaries_content.forEach(function (beneficiary) {
        beneficiary.find(function (element) {
          if (element["name"] == key) {
            element.options = newValue;
          }
        });
      });
    },
    mutation_BeneficiaryClabe(state, { data, array_key }) {
      let key = "clabe";
      state.form.beneficiaries_content[array_key - 1].find(function (element) {
        if (element["name"] == key) {
          element.value = data;
        }
      });
    },
    mutation_BeneficiaryClabeValidation(state, { data, array_key }) {
      let key = "clabe_validation";
      state.form.beneficiaries_content[array_key - 1].find(function (element) {
        if (element["name"] == key) {
          element.value = data;
        }
      });
    },

    /** Add Beneficiaty */
    mutation_BeneficiaryAddStructure(state) {
      let domains = state.api_data.email_domain.data;
      let clone_beneficiary = state.beneficiary_structure;
      let new_record = structuredClone(clone_beneficiary);
      new_record[0].readonly = false;
      new_record[3].options = domains;
      state.form.beneficiaries_content.push(new_record);

      // state.beneficiaries.forEach(function(value) {
      //     let new_record = structuredClone(clone_beneficiary)
      //     new_record['value'] = value
      //     new_record[0].value = 'TEST'
      //     new_record[3].options = domains
      //     state.form.beneficiaries_content.push(new_record)
      // })
    },
    /** Remove Beneficiaty */
    mutation_BeneficiaryRemoveStructure(state) {
      if (state.form.original_size < state.form.beneficiaries_content.length) {
        let lastAdded = state.form.beneficiaries_content.length - 1;
        state.form.beneficiaries_content.splice(lastAdded, 1);
      } else {
        return;
      }
    },
    meta(state, metadata) {
      state.meta_data[metadata];
    },
  },
  actions: {
    async getLadas({ state, dispatch }) {
      dispatch(
        "AxiosVuex/__request_gt",
        {
          endpoint: state.api_data.country_code,
          mutation: [
            "FormData/mutation_LadaOptions",
            // 'FormData/mutation_FamiliarLadaOptions',
            // 'FormData/mutation_BeneficiaryLadaOptions'
          ],
        },
        { root: true }
      );
    },
    async getDomains({ state, dispatch }) {
      dispatch(
        "AxiosVuex/__request_gt",
        {
          endpoint: state.api_data.email_domain,
          mutation: [
            "FormData/mutation_BeneficiaryDomainOptions",
            "FormData/mutation_DomainOptions",
            // 'FormData/mutation_FamiliarDomainOptions',
          ],
        },
        { root: true }
      );
    },
    async getDomainsBeneficiary({ state, dispatch }) {
      dispatch(
        "AxiosVuex/__request_gt",
        {
          endpoint: state.api_data.email_domain,
          mutation: ["FormData/mutation_BeneficiaryDomainOptions"],
        },
        { root: true }
      );
    },
    async getAssociate({ state, dispatch, commit }) {
      dispatch(
        "AxiosVuex/__request_gt",
        {
          endpoint: state.api_data.associate,
          mutation: [],
        },
        { root: true }
      ).then(() => {
        if (state.api_data.associate.data.is_registered) {
          commit("rt_redirect", "progress", { root: true });
        }
        state.show_form = true;
      });
    },
    async addBeneficiaryAndUpdateDomain({ commit }) {
      commit("mutation_BeneficiaryAddStructure");
    },
    async getBeneficiaries({ commit }) {
      commit("mutation_BeneficiaryAddStructure");
    },
    async send({ state, commit, dispatch }) {
      let hasBeneficiaries = state.form.aditional_data_2[0].value == 1;
      //console.log(hasBeneficiaries);

      if (state.form_sended) {
        return;
      }
      commit(
        "validateFields",
        { namespace: "FormData", state: "terms_conditions" },
        { root: true }
      );
      if (state.form_error) {
        return false;
      }

      commit(
        "validateFields",
        { namespace: "FormData", state: "general_content_cfdi" },
        { root: true }
      );
      if (state.form_error) {
        return false;
      }

      commit(
        "validateFields",
        {
          namespace: "FormData",
          state: "general_content",
          beneficiaries: hasBeneficiaries
            ? state.form.beneficiaries_content
            : false,
        },
        { root: true }
      );
      if (state.form_error) {
        return false;
      }

      if (
        state.api_data.associate.data.beneficiaries_count > 0 &&
        state.form.aditional_data_2[0].value != 1
      ) {
        dispatch("AlertData/warning", "Faltan datos de beneficiarios", {
          root: true,
        });
        if (state.form_error) {
          return false;
        }
      }

      // if (state.form.is_ex_associate[0].value == 1) {
      //     commit('validateFields', { "namespace": "FormData", "state": "familiar_data" }, { root: true })
      //     if (state.form_error) {
      //         return false
      //     }
      // }

      if (state.form.is_ex_associate[0].value == 0) {
        commit(
          "validateFields",
          { namespace: "FormData", state: "aditional_data_1" },
          { root: true }
        );
        if (state.form_error) {
          return false;
        }
      }

      if (state.form.aditional_data_1[0].value == 1) {
        commit(
          "validateFields",
          { namespace: "FormData", state: "foreign_form" },
          { root: true }
        );
        if (state.form_error) {
          return false;
        }

        commit(
          "validateFields",
          { namespace: "FormData", state: "aditional_data_2" },
          { root: true }
        );
        if (state.form_error) {
          return false;
        }
      }

      if (state.form.aditional_data_2[0].value == 1) {
        commit(
          "validateFields",
          {
            namespace: "FormData",
            state: "beneficiaries_content",
            is_array: true,
          },
          { root: true }
        );
        if (state.form_error) {
          return false;
        }
      }

      let pointer = state.form.general_content;
      state.api_data.handle_request.data.ex_associate = {
        curp: pointer[0].value,
        fullname: pointer[1].value,
        // lada: pointer[2].value,
        phone: pointer[2].value,
        email: pointer[3].value,
        // domain: pointer[4].value,
        is_foreign: state.form.aditional_data_1[0].value == 1 ? true : false,
        is_live: state.form.is_ex_associate[0].value == 1 ? false : true,
        food_beneficiaries: state.form.aditional_data_2[0].value,
        foreign_clabe: pointer[5].value,
      };

      //if ex_associate is foreign
      if (state.form.aditional_data_1[0].value == 1) {
        pointer = state.form.foreign_form;
        state.api_data.handle_request.data.foreign_form = {
          file_aditional: pointer[0].value,
        };
      }

      // if (state.form.is_ex_associate[0].value == 1) {
      //     pointer = state.form.familiar_data
      //     state.api_data.handle_request.data.familiar_data = {
      //         fullname: pointer[0].value,
      //         // lada: pointer[1].value,
      //         phone: pointer[1].value,
      //         email: pointer[2].value,
      //         domain: pointer[3].value,
      //         file_aditional: pointer[4].value,
      //     }
      // }

      //if ex_associate has beneficiaries
      if (state.form.aditional_data_2[0].value == 1) {
        pointer = state.form.beneficiaries_content;
        state.api_data.handle_request.data.beneficiaries_content = [];
        pointer.forEach((element) => {
          state.api_data.handle_request.data.beneficiaries_content.push({
            fullname: element[0].value,
            // lada: element[1].value,
            phone: element[1].value,
            email: element[2].value,
            clabe: element[4].value,
            // domain: element[3].value,
          });
        });
      }

      try {
        commit(
          "replace_parameters_url",
          {
            namespace: "FormData",
            api: "handle_request",
            parameters: {
              id: state.api_data.associate.data.id,
            },
          },
          { root: true }
        );

        commit("app_loaded_set", false, { root: true });
        state.form_sended = true;
        state.show_form = false;

        // dispatch('AxiosVuex/__request_update', {
        //     endpoint: state.api_data.handle_request,
        //     mutation: []
        // }, { root: true }).then(() => {
        //     if (state.success_form_sended == 200) {
        //         dispatch('send_documents')
        //     } else {
        //         state.form_sended = false
        //         state.show_form = true
        //     }
        // })

        //Comment follow code block for work locally - allow work whitout send document
        const responseDocuments = await dispatch("send_documents");
        if (responseDocuments.status !== 200) {
          state.form_sended = false;
          state.show_form = true;
          return false;
        }

        console.log("After send_documents");
        const response = await dispatch(
          "AxiosVuex/__request_update",
          {
            endpoint: state.api_data.handle_request,
            mutation: [],
          },
          { root: true }
        );

        commit("rt_redirect", "progress", { root: true });

        console.log("AFTER REQUEST:", response);
      } catch (e) {
        state.form_sended = false;
        state.show_form = true;
      }
    },
    async send_documents({ state, dispatch }) {
      try {
        state.api_data.handle_files.data = new FormData();
        state.api_data.handle_files.data.append(
          "cfdi",
          state.form.general_content_cfdi[0].value
        );

        if (state.form.aditional_data_1[0].value == 1) {
          state.api_data.handle_files.data.append(
            "address",
            state.form.foreign_form[0].value
          );
        } else {
          state.api_data.handle_files.data.delete("address");
        }

        // if (state.form.is_ex_associate[0].value == 1) {
        //     // With LADA state.form.familiar_data index is 5
        //     state.api_data.handle_files.data.append('representative_ine', state.form.familiar_data[4].value)
        // } else {
        //     state.api_data.handle_files.data.delete('representative_ine')
        // }

        // dispatch('AxiosVuex/__request_update_files', {
        //     endpoint: state.api_data.handle_files,
        //     mutation: []
        // }, { root: true }).then(() => {
        //     commit('rt_redirect', 'progress', { root: true })
        // })

        const response = await dispatch(
          "AxiosVuex/__request_update_files",
          {
            endpoint: state.api_data.handle_files,
            mutation: [],
          },
          { root: true }
        );

        // if (response.status === 200) {
        //     commit('rt_redirect', 'progress', { root: true });
        // }

        return response; // Retornar la respuesta
      } catch (e) {
        state.form_sended = false;
        state.show_form = true;
      }
    },
  },
  getters: {
    getIsFamiliar(state) {
      return state.form.is_ex_associate[0].value == 1;
    },
    getIsForeign(state) {
      return state.form.aditional_data_1[0].value == 1;
    },
    getIsFoodPension(state) {
      return state.form.aditional_data_2[0].value == 1;
    },
  },
};
